<template>
  <v-container fluid>
    <div class="d-flex flex-wrap py-2 align-center" style="gap: 10px">
      <v-btn class="ma-1" color="primary" small to="/liquidaciones">
        <v-icon left>mdi-arrow-left</v-icon>Volver a las liquidaciones
      </v-btn>
      <h3>Nueva liquidación {{ canal }}</h3>
      <v-alert class="ma-0" text :type="importeLiquidacion < 0 ? 'error' : 'info'">Importe liquidacion: <b>{{ importeLiquidacion }} €</b></v-alert>
      <v-alert class="ma-0" text :type="nuevoSaldo < 0 ? 'error' : 'success'">Nuevo saldo: <b>{{ nuevoSaldo }} €</b></v-alert>
      <v-spacer />
      <v-hover v-slot="{ hover }" >
        <v-alert @click.native="selected = []" style="cursor: pointer" class="ma-0" text :type="hover ? 'error' : 'info'" :icon="hover ? 'mdi-close' : 'mdi-information'" >
          <span v-if="hover">Quitar seleccionados</span>
          <span v-else><b>{{ selected.length }}</b> seleccionados</span>
        </v-alert>
      </v-hover>
    </div>

    <v-card>
      <v-data-table
        fixed-header
        :items="lineas"
        :headers="lineasHeader"
        :calculate-widths="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        show-select
        :single-select="false"
        checkbox-color="success"
        item-key="KeyContrato"
        v-model="selected"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="lineas"
            v-model="inlineFilters2"
          ></table-filters>
        </template>

        <template v-slot:item.Situacion="{ item }">
          <div class="d-flex align-center">
            <status-chip :value="item.Situacion" />
            <v-alert
              class="mb-0 ml-2"
              dense
              type="info"
              text
              v-if="
                !Number(item.aDevolver) &&
                  ['Baja', 'Baja por modificación'].includes(item.Situacion)
              "
              >Aún no ha sido facturado</v-alert
            >
          </div>
        </template>

        <template v-slot:item.CodigoContrato="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                link
                :to="
                  `/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`
                "
                :color="Number(item.aDevolver) ? 'error' : 'secondary'"
                text
                >{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn
              >
            </template>
            <span>Detalles del contrato</span>
          </v-tooltip>
        </template>

        <!-- <template v-slot:item.renovacion="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                :color="Number(item.aDevolver) ? 'error' : 'secondary'"
                text
                >{{ item.renovacion }}</v-btn
              >
            </template>
            <span>Renovación {{ item.renovacion }}</span>
          </v-tooltip>
        </template> -->

        <template v-slot:item.Identificador="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                link
                :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
                :color="Number(item.aDevolver) ? 'error' : 'secondary'"
                text
                >{{ item.Identificador }}</v-btn
              >
            </template>
            <span>Detalles del cliente</span>
          </v-tooltip>
        </template>

        <template v-slot:item.CodigoCUPS="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                link
                :to="`/detalles_cups?cups=${item.CodigoCUPS}`"
                :color="Number(item.aDevolver) ? 'error' : 'secondary'"
                text
                >{{ item.CodigoCUPS }}</v-btn
              >
            </template>
            <span>Detalles del CUPS</span>
          </v-tooltip>
        </template>

        <template v-slot:item.FechaContrato="{ item }">
          <span v-text="parseDate(item.FechaContrato)" />
        </template>
        <template v-slot:item.FechaAlta="{ item }">
          <span v-text="parseDate(item.FechaAlta)" />
        </template>
        <template v-slot:item.FechaBaja="{ item }">
          <span v-text="parseDate(item.FechaBaja)" />
        </template>
        <template v-slot:item.FechaVto="{ item }">
          <span v-text="parseDate(item.FechaVto)" />
        </template>

        <template v-slot:item.comision="{ item }">
          <v-chip
            v-if="cambiarImporte.contratos[item.KeyContrato] == null"
            label
            >{{ item.comision | eur }}</v-chip
          >
          <v-chip v-else label>
            <s class="mr-2">{{ item.comision * 1 }}</s>
            {{ cambiarImporte.contratos[item.KeyContrato].nuevo }}
            €
          </v-chip>
          <v-btn @click.stop="cambiarImporteF(item)" icon color="primary" small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.porcentajeEspecial="{ item }">
          <v-chip
            v-if="item.porcentajeEspecial"
            label
            small
            >{{ item.porcentajeEspecial }} %</v-chip
          >
        </template>

        <template v-slot:item.aDevolver="{ item }">
          <span :class="[!Number(item.aDevolver) || 'error--text']">{{
            Number(item.aDevolver) ? "Sí" : "No"
          }}</span>
        </template>
      </v-data-table>
    </v-card>
      <v-card-actions class="align-center">
        <v-alert class="mb-0" text v-if="repetido" dense type="error"
          >Ya existe una factura con este nombre</v-alert
        >
        <v-spacer />
        <v-alert
          class="mb-0"
          text
          v-if="Object.keys(cambiarImporte.contratos).length > 0"
          dense
          type="info"
          >{{ Object.keys(cambiarImporte.contratos).length }} importes
          cambiados</v-alert
        >
        <v-btn :disabled="lineas.length == 0" @click.stop="generarAutofactura" :loading="loading" large color="primary">
          <v-icon left>mdi-cash-register</v-icon>
          Generar</v-btn
        >
      </v-card-actions>

    <v-dialog
      v-if="cambiarImporte.contrato != null"
      :value="cambiarImporte.contrato != null"
      @input="(v) => (v === false ? cambiarImporte.resolve() : null)"
      @keydown.enter="cambiarImporte.resolve()"
      max-width="350"
    >
      <v-card class="pa-3">
        <v-form ref="cambioImporte">
          <!-- <v-col cols="auto">
						<v-btn @click.stop="cambiarImporte.reject" icon color="error">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>-->
          <v-text-field
            dense
            hide-details="auto"
            filled
            v-model="cambiarImporte.contratos[cambiarImporte.contrato].nuevo"
            :rules="[rules.req, rules.isNumber]"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="error" @click.stop="cambiarImporte.reject"
              >Cancelar</v-btn
            >
            <v-btn
              small
              text
              color="primary"
              @click.stop="
                $refs.cambioImporte.validate() ? cambiarImporte.resolve() : null
              "
              >Aceptar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />

  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, timeAgo } from "@/utils/index.js";
import { req, isNumber } from "@/utils/validations.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    idUsuario: String,
    canal: String,
  },
  data() {
    return {
      p: {
        show: false,
        resolve: null,
        reject: null,
      },
      contratos: [],
      lineas: [],
      loading: false,
      repetido: false,
      selected: [],
      ignoreSelect: [],
      checksPanel: false,
      checks: {},
      rules: { req, isNumber },
      lineasHeader: [
        { text: "Situacion", value: "Situacion", dataType: "select" },
        { text: "Canal", value: "canal" },
        { text: "SubAgente", value: "subAgente" },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "Renovación", value: "renovacion" },
        // { text: 'Fecha Contrato', value: 'FechaContrato', dataType: 'date' },
        { text: "Fecha Alta", value: "FechaAlta", dataType: "date" },
        { text: "Fecha Baja", value: "FechaBaja", dataType: "date" },
        { text: "Fecha Vto", value: "FechaVto", dataType: "date" },
        { text: "Meses Activado", value: "Diferencia" },
        { text: "CUPS", value: "CodigoCUPS" },
        { text: "Cliente", value: "Identificador" },
        { text: "Nombre Cliente", value: "Denominacion" },
        { text: "Comisionado", value: "yaPagado" },
        { text: "Base Imponible", value: "comision" },
        { text: "Porcentaje especial", value: "porcentajeEspecial" },
        { text: "Devolución", value: "aDevolver", dataType: "bool" },
        // { text: 'A DEVOLVER', value: 'aDevolver' },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters2[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      }),

      inlineFilters: {},
      inlineFilters2: {},
      colaborador: null,
      autofactura: {
        fecha: [
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate(),
        ].join("-"),
        nDoc: null,
        showDate: false,
      },
      generandoAutofactura: false,

      mostrarNoFacturables: false,
      colaboradores: [],
      ultimaLiquidacion: {},

      cambiarImporte: {
        contrato: null,
        contratos: {},
      },
    };
  },
  computed: {
    fechaFormateada() {
      return parseDate(this.autofactura.fecha);
    },
    contratosFiltrados() {
      return this.mostrarNoFacturables
        ? this.contratos
        : this.contratos.filter((c) => c.facturable);
    },
    percentDiff() {
      return parseFloat(
        ((Number(
          this.cambiarImporte.contratos[this.cambiarImporte.contrato].nuevo
        ) -
          Number(
            this.cambiarImporte.contratos[this.cambiarImporte.contrato].viejo
          )) /
          Number(
            this.cambiarImporte.contratos[this.cambiarImporte.contrato].nuevo
          )) *
          100
      ).toFixed(2);
    },
    importeLiquidacion() {
      const sumarImportes = (arr) => arr.reduce((t, c) => {
        const comision = this.cambiarImporte.contratos[c.KeyContrato] ? this.cambiarImporte.contratos[c.KeyContrato].nuevo : c.comision
        return parseFloat(t + Number(comision)).toFixed(2) * 1
      }, 0)
      if (this.selected.length > 0) {
        return sumarImportes(this.selected)
      }
      return sumarImportes(this.lineas)
    },
    nuevoSaldo() {
      const acumulativo = this.ultimaLiquidacion.acumulativo < 0 ? this.ultimaLiquidacion.acumulativo : 0.0;
      return parseFloat(acumulativo + this.importeLiquidacion).toFixed(2)
    }
  },
  async mounted() {
    await this.getContratos(this.canal);
    await this.getLiquidaciones();
  },
  methods: {
    parseDate,
    timeAgo,

    async getContratos(canal) {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/contratosSinLiquidar.php`,
        params: {
          canal,
          token: this.$store.getters.getJwtEmpresa,
        },
      })
      const importe = data .filter((c) => c.aDevolver == "0") .reduce((a, c) => Number(c.comision) + a, 0);
      const importeDevoluciones = data .filter((c) => c.aDevolver == "1") .reduce((a, c) => Number(c.comision) + a, 0);
      this.contratos.push({
        ...this.colaboradores.find(
          (col) => col.NombreContrato == canal || col.name == canal
        ),
        canal,
        NContratos: data.filter((c) => c.aDevolver == "0").length,
        importe,
        NDevoluciones: data.filter((c) => c.aDevolver == "1").length,
        importeDevoluciones,
        baseImponible: importe + importeDevoluciones,
      });
      this.lineas = data;
      this.loading = false;
    },
    async getLiquidaciones() {
      if (!this.idUsuario) return;
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/liquidaciones.php`,
        params: {
          idUsuario: this.idUsuario,
          ultima: 1
        },
      });
      // this.liquidaciones = data;
      this.$set(this.$data, 'ultimaLiquidacion', data);
      this.loading = false;
    },
    async generarAutofactura() {
      const r = await this.$refs.confirm.open(`¿ Estás seguro de que quieres generar la liquidación ?`, `Liquidación por valor de ${ this.importeLiquidacion }€`, { width: 600 })
      if (!r) return;

      this.loading = true;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario, IdAgente } = str.iss;


      axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/nuevaLiquidacion.php`,
        data: {
          canal: [this.canal],
          fecha: new Date(this.autofactura.fecha).getTime(),
          token: this.$store.getters.getJwtEmpresa,
          cambiosImporte: this.cambiarImporte.contratos,
          selected: this.selected.map((item) => item.KeyContrato),
          IdAgente,
        },
      }).then((res) => {
        this.colaborador = null;
        this.$root.$emit("snack", "Liquidación generada correctamente");
        this.$router.push(`/liquidaciones/${this.idUsuario}`)
      }).finally(() => 
        this.loading = false
      );
    },
    async generarAutofacturaLote() {
      try {
        this.generandoAutofactura = true;
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
          data: {
            canal: this.selected.map((f) => f.canal),
            fecha: new Date(this.autofactura.fecha).getTime(),
            token: this.$store.getters.getJwtEmpresa,
          },
        });
        this.generandoAutofactura = false;
        this.$root.$emit("snack", "Autofacturas generadas correctamente");
        this.$router.push({ name: "Autofacturas" });
      } catch (e) {
        this.generandoAutofactura = false;
        this.p.show = false;
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al generar las autofacturas"
        );
      }

      return;
    },
    async cambiarImporteF({ KeyContrato, comision }) {
      const auxPromise = () => {
        return new Promise(async (resolve, reject) => {
          this.$set(this.cambiarImporte.contratos, KeyContrato, {
            nuevo: comision * 1,
            viejo: comision * 1,
          });
          this.cambiarImporte.contrato = (KeyContrato);
          this.cambiarImporte.resolve = resolve;
          this.cambiarImporte.reject = reject;
        });
      };

      try {
        await auxPromise();
        if (
          this.cambiarImporte.contratos[KeyContrato].nuevo ==
          this.cambiarImporte.contratos[KeyContrato].viejo
        )
          throw new Error("Importe igual al anterior");
        this.cambiarImporte.contrato = null;
        this.cambiarImporte.resolve = null;
        this.cambiarImporte.reject = null;
        console.log(this.cambiarImporte.contratos);
      } catch (e) {
        this.cambiarImporte.contrato = null;
        this.cambiarImporte.resolve = null;
        this.cambiarImporte.reject = null;
        this.$delete(this.cambiarImporte.contratos, KeyContrato);
      }
    },
  },
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
  /* background: #f1f1f1 !important; */
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid white;
  margin: 0 auto;
}
</style>
